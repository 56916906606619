import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "../../shared/notification";
import { MainService, setToken } from "../../shared/services";
import { hookStateReducer, onlyNumber } from "../repetitive/RepetitiveFunction";
import Loader from "../loader/Loader";
const is = {
  mode: "",
  no_round: "",
  player: "",
  useBot: "",
  isEnabled: false,
  entryFee: "",
  rake: "",
  lobbyId: "",
  gameType: "",
  lobbyname: "",
  LobyTime: "",
  bonus: "",
  small_title: "",
  ImageUrl: '',
};

const EditLobby = () => {
  const [ImagePreview, setImagePreview] = useState("/images/NoImage.jpg");
  const [loader, setLoader] = useState(false)
  const [state, dispatch] = useReducer(hookStateReducer, is);
  const {
    mode,
    isEnabled,
    player,
    entryFee,
    rake,
    lobbyname,
    lobbyId,
    LobyTime,
    bonus,
    small_title,
    ImageUrl,
  } = state;
  const token = sessionStorage.getItem("Token");
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    setToken(token);
    setLoader(true)
    MainService.getLobbyDetailsById({ id: id }).then((res) => {
      if (res.success === true) {
        const Data = res.data;
        dispatch({
          lobbyId: Data._id,
          mode: Data.types_of_game,
          player: Data.num_of_players,
          rake: Data.winning_amount,
          entryFee: Data.entery_fees,
          isEnabled: Data.enable,
          lobbyname: Data.lobby_name,
          LobyTime: Data.timer,
          bonus: Data.bonus,
          small_title: Data.league_sub_text,
          ImageUrl: Data.league_icon,
        });
        setImagePreview(Data.league_icon);
      }
      setLoader(false)
    });
    //eslint-disable-next-line
  }, []);

  const handleImageChange = (e) => {
    let files = e.target.files;
    for (let i = 0, f; (f = files[i]); i++) {
      if (!files[0].type.match("image.*")) {
        continue;
      }
      let reader = new FileReader();
      reader.onload = function (e) {
        dispatch({ ImageUrl: files[0] });
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(f);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "rake") {
      const temp = value.split(".")[1];
      if (temp && temp.length > 2) return;
    }
    if (type === "checkbox") dispatch({ [name]: checked });
    // if (name === "Time") console.log(name, value);
    else dispatch({ [name]: value });
  };
  const handleSubmit = () => {
    setToken(token);
    setLoader(true)
    // const data = {
    //     lobby_name: lobbyname,
    //     types_of_game: mode,
    //     num_of_players: +player,
    //     rake: +rake,
    //     entery_fees: +entryFee,
    //     timer: LobyTime,
    //     enable: isEnabled,
    //   // id: lobbyId
    // };
    const data = new FormData();
    let title = player == 2 ? "duo" : "quarto";
    if (player == 2) {
      title = "duo";
    } else if (player == 4) {
      title = "quarto";
    } else {
      title = "";
    }
    data.append("lobby_name", lobbyname);
    data.append("types_of_game", mode);
    data.append("num_of_players", +player);
    data.append("winning_amount", +rake);
    data.append("entery_fees", +entryFee);
    data.append("league_sub_text", small_title);
    data.append("league_name", title);
    if (ImageUrl !== ImagePreview) {
      data.append("image", ImageUrl);
    }

    data.append("bonus", +bonus);
    data.append("timer", +LobyTime);
    data.append("enable", isEnabled.toString());
    MainService.updateLobbyData(lobbyId, data).then((res) => {
      if (res.success === true) {
        Toast.fire({
          icon: "success",
          title: res.message,
        });
        setLoader(true)
        navigate(`/admin/lobby-list`);
      } else {
        Toast.fire({
          icon: "error",
          title: res.message,
        });
        setLoader(true)
        // this.errorMessage = res.message;
      }
    });
  };
  return (
    <>
      <div className="dashboard-wrapper">
        <div className="inner-div">
          <div className="heading addnew">
            <h3>Update lobby</h3>
          </div>
          <div className="center-container">
            <div className="user-detail">
              <form onSubmit={handleSubmit}>
                <div className="user-profile">
                  <div className="user_form_wpr forgot_form_wpr">
                    <div className="form_group">
                      <label>Lobby ID</label>
                      <input
                        className="input"
                        type="text"
                        name="lobbyid"
                        value={lobbyId || ""}
                        placeholder="Enter lobby ID"
                        disabled
                      />
                    </div>
                    <div className="form_group">
                      <label>Lobby name</label>
                      <input
                        className="input"
                        type="text"
                        name="lobbyname"
                        value={lobbyname || ""}
                        placeholder="Enter Lobby name"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form_group">
                      <label>Type of Game</label>
                      <div className="label_section">
                        <label className="container">
                          Cash
                          <input
                            type="radio"
                            name="mode"
                            value="cash"
                            checked={mode === "cash" || ""}
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="container">
                          Play with friends
                          <input
                            type="radio"
                            name="mode"
                            value="PWF"
                            checked={mode === "PWF" || ""}
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                        {/* <label className="container" >Deals
                                                <input type="radio" name="gameType" value="Deals" checked={gameType === "Deals" || ''} onChange={handleChange} />
                                                <span className="checkmark"></span>
                                            </label> */}
                      </div>
                      <div className="invalid-feedback"></div>
                    </div>
                    <div className="form_group">
                      <label>Time</label>
                      <input
                        className="input"
                        type="text"
                        name="LobyTime"
                        value={LobyTime || ""}
                        onChange={handleChange}
                        placeholder="enter time in second"
                        onKeyPress={onlyNumber}
                        min={1}
                        max={1800}
                        maxLength={4}
                      />
                      <div className="invalid-feedback">
                        {/* {validation.Time && <div>{validation.Time}</div>} */}
                      </div>
                    </div>
                    <div className="form_group">
                      <label>No of Players</label>
                      <select
                        className="input"
                        name="player"
                        value={player || ""}
                        onChange={handleChange}
                      >
                        <option value="" hidden></option>
                        <option value="2">2</option>
                        {/* <option value="3">3</option> */}
                        <option value="4">4</option>
                      </select>
                      <div className="invalid-feedback">
                        {/* {validation.player && <div>{validation.player}</div>} */}
                        {/* <div >No of player is required.</div> */}
                      </div>
                    </div>

                    <div className="form_group">
                      <label>leage image</label>
                      <div
                        className="flex"
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={ImagePreview}
                          className="selected-icon"
                          alt="setted icons"
                        />
                        <input
                          className="input"
                          type="file"
                          name="ImagePreview"
                          // value={ImagePreview}
                          onChange={(e) => handleImageChange(e)}
                        />
                      </div>
                      {/* <div className="invalid-feedback">
                      {validation.ImageUrl && <div>{validation.ImageUrl}</div>}
                    </div> */}
                    </div>

                    <div className="form_group">
                      <label>sub-title</label>
                      <input
                        className="input"
                        type="text"
                        name="small_title"
                        value={small_title}
                        placeholder="Enter sub title"
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {/* <div className="invalid-feedback">
                      {validation.small_title && (
                        <div>{validation.small_title}</div>
                      )}
                    </div> */}
                    </div>

                    <div className="form_group">
                      <label>bonus</label>
                      <input
                        className="input"
                        type="text"
                        name="bonus"
                        value={bonus}
                        placeholder="Enter bonus(%)"
                        onKeyPress={onlyNumber}
                        maxLength="10"
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>

                    <div className="form_group">
                      <label>Winning amount</label>
                      <input
                        className="input"
                        type="text"
                        name="rake"
                        value={rake || ""}
                        placeholder="Enter Winning amount"
                        onKeyPress={onlyNumber}
                        maxLength="10"
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">
                        {/* {validation.rake && <div>{validation.rake}</div>} */}
                        {/* <span >Rake is required.</span>
                                            <span> Please add percentage value.</span > */}
                      </div>
                    </div>
                    <div className="form_group">
                      <label>Entry fee</label>
                      <input
                        className="input"
                        type="text"
                        name="entryFee"
                        value={entryFee || ""}
                        placeholder="Enter entry fee"
                        onKeyPress={onlyNumber}
                        maxLength="10"
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">
                        {/* {validation.entryFee && <div>{validation.entryFee}</div>} */}
                        {/* <span >Entry fee is required.</span> */}
                      </div>
                    </div>
                    <div className="form_group">
                      <div className="status">
                        <label>Enabled/Disabled</label>
                      </div>
                    </div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="isEnabled"
                        checked={isEnabled}
                        onChange={handleChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="btn_group">
            <div>
              <div className="btn">
                <button className="pt_btn pt_btn_orange" onClick={handleSubmit}>
                  Save
                </button>
                <button
                  className="pt_btn pt_btn_orange"
                  onClick={() => navigate("/admin/lobby-list")}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && < Loader />}
    </>
  );
};

export default EditLobby;

import { Toast } from "../../shared/notification";
export function onError(response, error) {
    console.log("errorr==>", response)
    if (response.message !== undefined) console.log("onError====>", response);
    return response;
}

export function onSuccess(response) {
    // console.log("responce==>", response)
    if (response.data.status === 401 && response.data.responseType !== "UNAUTHORIZED") {
        if (response.data.status === 401) {
            Toast.fire({
                icon: 'error',
                title: response.data.message
            })
        }
        // window.location.pathname = "/"
        // if (response.data.message === "The site is under maintenance!") store.dispatch({ type: "CHANGE_MAINTENANCE", payload: true })
        // else 
        // if (response.data.message === "Unauthorized Access!") {
        //     localStorage.removeItem("Token");
        //     // redirectTologin();
        // } else if (response.data.message === "Access Denied!" && !window.location.pathname.includes("blog")) {
        //     window.location.pathname = "/home";
        // }
    }
    else return response.data;
}
export const hookStateReducer = (state, payload) => {
    return { ...state, ...payload };
};

export const onlyNumber = event => {
    const keycode = event.which;
    if (keycode === 13) {
    } else if (
        !(
            event.shiftKey === false &&
            (keycode === 8 ||
                keycode === 46 ||
                keycode === 37 ||
                keycode === 39 ||
                (keycode >= 48 && keycode <= 57))
        )
    )
        event.preventDefault();
};
// import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { lazy } from 'react';
import PrivateRoute from './component/PrivateRoute';
import Login from "./component/auth/login";
import LobbyList from "./component/admin/LobbyList";
import AddLobby from "./component/admin/AddLobby";
import EditLobby from "./component/admin/EditLobby";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/admin/lobby-list" element={<PrivateRoute cmp={LobbyList} />} />
          <Route path="/admin/add-lobby" element={<PrivateRoute cmp={AddLobby} />} />
          <Route path="/admin/edit-lobby/:id" element={<PrivateRoute cmp={EditLobby} />} />


        </Routes>
      </Router>
    </div>
  );
}

export default App;

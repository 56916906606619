import React, { useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toast } from '../../../shared/notification'
import { MainService, setToken } from '../../../shared/services'
import { hookStateReducer } from '../../repetitive/RepetitiveFunction'
const is = {
    username: "",
    password: "",
    nameError: false,
    passwordError: false
}
const Login = () => {
    const [state, dispatch] = useReducer(hookStateReducer, is)
    const navigate = useNavigate()
    const { username, password, nameError, passwordError } = state
    useEffect(() => {
        if (sessionStorage.getItem("Token")) {
          navigate("/admin/lobby-list");
        }
        //eslint-disable-next-line
    }, []);
    const handleChange = ({ target: { name, value } }) => dispatch({ [name]: value })
    const handleSubmit = (e) => {
        e.preventDefault();
        if (username !== "") {
            dispatch({ nameError: false })
            if (password !== "") {
                dispatch({ passwordError: false })
                MainService.login({ username: username, password: password }).then(res => {
                    if (res.success === true) {
                        sessionStorage.setItem('Token', res.data.token);
                        setToken(res.data.token)
                        Toast.fire({
                            icon: 'success',
                            title: 'Login Sucessfully'
                        })
                        navigate("admin/lobby-list");
                    }
                    else {
                        Toast.fire({
                            icon: 'error',
                            title: res.msg
                        })
                    }
                })
            }
            else {
                dispatch({ passwordError: true })
                return false
            }
        } else {
            dispatch({ nameError: true })
            return false
        }
    }
    return (
        <div className="login" >
            <form className="login-container" onSubmit={handleSubmit}>
                <label>Username</label>
                <p>
                    <input type="text" name="username" placeholder="Enter username" value={username} onChange={handleChange} autoComplete="off" />
                    <span className="invalid-feedback"></span>
                    {nameError && <span >Username is required.</span>}
                </p >
                <label>Password</label>
                <p>
                    <input type="password" name='password' placeholder="Enter password" value={password} onChange={handleChange} />
                    <span className="invalid-feedback"></span>
                    {passwordError && <span>Password is required.</span>}
                </p >
                <p>
                    <input type="submit" value="Log In" />
                </p>
            </form >
        </div >
    )
}

export default Login
let prodEnv;
// const domain = window.location.hostname
const devApi = "https://ludo-node.kuberafantasy.live:3004/admin"
// const localApi = "http://172.20.11.52:5000"
// if (domain.includes("capital-source-cms.artoon.in")) prodEnv = "prod"
// const baseUrl = prodEnv === "prod" ? devApi : localApi;
const url = devApi;
const constant = {
    API_URL: url
}
export default constant;
import React, { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../shared/notification";
import { MainService, setToken } from "../../shared/services";
import Loader from "../loader/Loader";
import { hookStateReducer, onlyNumber } from "../repetitive/RepetitiveFunction";

const is = {
  // mode: "",
  // no_round: "",
  lobbyName: "",
  player: "",
  useBot: "",
  isEnabled: true,
  entryFee: "",
  rake: "",
  bonus: "",
  Time: 0,
  small_title: '',
  gameType: "",
  ImageUrl: "",
};
const AddLobby = () => {
  const [ImagePreview, setImagePreview] = useState("/images/NoImage.jpg");
  const [loader, setLoader] = useState(false)
  const [state, dispatch] = useReducer(hookStateReducer, is);
  const {
    lobbyName,
    player,
    isEnabled,
    entryFee,
    rake,
    bonus,
    Time,
    small_title,
    gameType,
    ImageUrl,
  } = state;
  const [validation, setValidation] = useState({
    // mode: "",
    // no_round: "",
    lobbyName: "",
    player: "",
    useBot: "",
    entryFee: "",
    rake: "",
    bonus: "",
    Time: "",
    small_title: '',
    isValid: false,
    ImageUrl: "",
  });
  const token = localStorage.getItem("Token");
  const navigate = useNavigate();
  const checkValidation = () => {

    let error = { ...validation };
    if (!lobbyName) {
      error.lobbyName = "Lobby name is required.";
    } else {
      error.lobbyName = "";
    }
    if (!gameType) {
      error.gameType = "Type of Game is required.";
    } else {
      error.gameType = "";
    }
    if (!player) {
      error.player = "No of player is required.";
    } else {
      error.player = "";
    }
    if (!entryFee) {
      error.entryFee = "Entry fee is required.";
    } else {
      error.entryFee = "";
    }
    if (!rake) {
      error.rake = "Winning amount is required.";
    }
    //  else if (Number(rake) > 100) {
    //   error.rake = "Please add percentage value.";
    // }
    else {
      error.rake = "";
    }
    if (!Time) {
      error.Time = "Timer is required.";
    } else if (Time > 1800) {
      error.Time = "Please add maximum 1800 seconds value.";
    }
    else {
      error.Time = "";
    }
    if (!ImageUrl) {
      error.ImageUrl = "image is required.";
    } else {
      error.ImageUrl = ""
    }
    if (!small_title) {
      error.small_title = "Please add sub title .";
    } else {
      error.small_title = ''
    }
    if (!bonus) {
      error.bonus = "Please add bonus.";
    } else if (Number(bonus) > 100) {
      error.bonus = "Please add percentage value.";
    }
    else {
      error.bonus = "";
    }
    setValidation(error);
  };

  const handleChange = (e) => {
    const { name, value, validity } = e.target;
    if (name === "rake" || name === "bonus") {
      const temp = value.split(".")[1];
      if (temp && temp.length > 2) return;
    }

    dispatch({ [name]: value });
    if (name === "lobbyName" && value !== "")
      setValidation({ ...validation, lobbyName: "" });
    else if (name === "gameType" && value !== "")
      setValidation({ ...validation, gameType: "" });
    else if (name === "player" && value !== "")
      setValidation({ ...validation, player: "" });
    else if (name === "entryFee" && value !== "")
      setValidation({ ...validation, entryFee: "" });
    else if (name === "rake" && value !== "")
      setValidation({ ...validation, rake: "" });
    else if (name === "bonus" && value !== "")
      setValidation({ ...validation, bonus: "" });
    else if (name === "Time" && value !== "" && validity.valid) {
      setValidation({ ...validation, Time: '' });
    }
    else if (name === "small_title" && value !== "") {
      setValidation({ ...validation, small_title: "" });
    }
  };

  const handleImageChange = (e) => {
    let files = e.target.files;
    for (let i = 0, f; (f = files[i]); i++) {
      if (!files[0].type.match("image.*")) {
        continue;
      }
      let reader = new FileReader();
      reader.onload = function (e) {
        dispatch({ ImageUrl: files[0] });
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(f);
    }
    setValidation({ ...validation, ImageUrl: "" });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      gameType !== "" &&
      lobbyName !== "" &&
      player !== "" &&
      entryFee !== "" &&
      rake !== "" &&
      Time !== "" &&
      small_title !== "" &&
      ImageUrl !== "" &&
      bonus !== ""
      // + rake < 101
    ) {
      setToken(token);
      setLoader(true)
      // const data = {
      //   lobby_name: lobbyName,
      //   types_of_game: gameType,
      //   num_of_players: +player,
      //   rake: +rake,
      //   entery_fees: +entryFee,
      //   league_sub_text: small_title,
      //   league_name: "duo",
      //   league_icon: ImageUrl,
      //   bonus: +bonus,
      //   timer: +Time,
      //   enable: isEnabled,
      // };
      const data = new FormData();
      let title = player == 2 ? "duo" : "quarto"
      if (player == 2) {
        title = "duo";
      } else if (player == 4) {
        title = "quarto";
      } else {
        title = '';
      }
      data.append('lobby_name', lobbyName);
      data.append('types_of_game', gameType);
      data.append('num_of_players', +player);
      data.append('winning_amount', +rake);
      data.append('entery_fees', +entryFee);
      data.append('league_sub_text', small_title);
      data.append("league_name", title);
      data.append("image", ImageUrl);
      data.append('bonus', +bonus);
      data.append('timer', +Time);
      data.append('enable', isEnabled);

      MainService.addLobby(data).then((res) => {
        if (res.success === true) {
          Toast.fire({
            icon: "success",
            title: res.message,
          });
          setLoader(false)
          navigate(`/admin/lobby-list`);
        } else {
          Toast.fire({
            icon: "error",
            title: res.message,
          });
          setLoader(false)
        }
      });
    } else {
      return checkValidation();
    }
  };
  return (
    <div className="dashboard-wrapper">
      <div className="inner-div">
        <div className="heading addnew">
          <h3>Create new lobby</h3>
        </div>
        <div className="center-container">
          <div className="user-detail">
            <form onSubmit={handleSubmit}>
              <div className="user-profile">
                <div className="user_form_wpr forgot_form_wpr">
                  <div className="form_group">
                    <label>Lobby name</label>
                    <input
                      className="input"
                      type="text"
                      name="lobbyName"
                      value={lobbyName}
                      placeholder="Enter Lobby name"
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <div className="invalid-feedback">
                      {validation.lobbyName && (
                        <div>{validation.lobbyName}</div>
                      )}
                    </div>
                  </div>
                  <div className="form_group">
                    <label>Type of Game</label>
                    <div className="label_section">
                      <label className="container">
                        Cash
                        <input
                          type="radio"
                          name="gameType"
                          value="cash"
                          onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="container">
                        Play with friends
                        <input
                          type="radio"
                          name="gameType"
                          value="PWF"
                          onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="invalid-feedback">
                      {validation.gameType && <div>{validation.gameType}</div>}
                    </div>
                  </div>
                  <div className="form_group">
                    <label>Time</label>
                    <input
                      className="input"
                      type="text"
                      value={Time}
                      onChange={handleChange}
                      name="Time"
                      placeholder="enter time in second"
                      onKeyPress={onlyNumber}
                      //   pattern="[0-9]*"
                      min={1}
                      max={1800}
                      maxLength={4}
                    />
                    <div className="invalid-feedback">
                      {validation.Time && <div>{validation.Time}</div>}
                    </div>
                  </div>
                  <div className="form_group">
                    <label>No of Players</label>
                    <select
                      className="input"
                      name="player"
                      onChange={handleChange}
                      value={player}
                    >
                      <option value="" hidden>select player </option>
                      <option value="2">2</option>
                      {/* <option value="3">3</option> */}
                      <option value="4">4</option>
                    </select>
                    <div className="invalid-feedback">
                      {validation.player && <div>{validation.player}</div>}
                    </div>
                  </div>
                  <div className="form_group">
                    <label>No of Players title</label>
                    <input
                      type='input'
                      className="input"
                      name="player"
                      value={player == "2" ? "duo" : "qurant"}
                      disabled
                    />
                    {/* <div className="invalid-feedback">
                      {validation.player && <div>{validation.player}</div>}
                    </div> */}
                  </div>
                  <div className="form_group">
                    <label>sub-title</label>
                    <input
                      className="input"
                      type="text"
                      name="small_title"
                      value={small_title}
                      placeholder="Enter sub title"
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <div className="invalid-feedback">
                      {validation.small_title && (
                        <div>{validation.small_title}</div>
                      )}
                    </div>
                  </div>
                  <div className="form_group">
                    <label>leage image</label>
                    <div
                      className="flex"
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={ImagePreview}
                        className="selected-icon"
                        alt="setted icons"
                      />
                      <input
                        className="input"
                        type="file"
                        name=""
                        onChange={(e) => handleImageChange(e)}
                        required
                      />
                    </div>
                    <div className="invalid-feedback">
                      {validation.ImageUrl && <div>{validation.ImageUrl}</div>}
                    </div>
                  </div>
                  <div className="form_group">
                    <label>bonus</label>
                    <input
                      className="input"
                      type="text"
                      name="bonus"
                      value={bonus}
                      placeholder="Enter bonus(%)"
                      onKeyPress={onlyNumber}
                      maxLength="10"
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <div className="invalid-feedback">
                      {validation.bonus && <div>{validation.bonus}</div>}
                    </div>
                  </div>
                  <div className="form_group">
                    <label>Winning amount</label>
                    <input
                      className="input"
                      type="text"
                      name="rake"
                      value={rake}
                      placeholder="Enter Winning amount"
                      onKeyPress={onlyNumber}
                      maxLength="10"
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <div className="invalid-feedback">
                      {validation.rake && <div>{validation.rake}</div>}
                    </div>
                  </div>
                  <div className="form_group">
                    <label>Entry fee</label>
                    <input
                      className="input"
                      type="text"
                      name="entryFee"
                      value={entryFee}
                      placeholder="Enter entry fee"
                      onKeyPress={onlyNumber}
                      maxLength="10"
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <div className="invalid-feedback">
                      {validation.entryFee && <div>{validation.entryFee}</div>}
                    </div>
                  </div>
                  <div className="form_group">
                    <div className="status">
                      <label>Enabled/Disabled</label>
                    </div>
                  </div>
                  <label className="switch">
                    <input type="checkbox" defaultChecked={isEnabled} onChange={(e) => dispatch({ isEnabled: e.target.checked })} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="btn_group">
          <div>
            <div className="btn">
              <button
                className="pt_btn pt_btn_orange"
                onClick={() => navigate("/admin/lobby-list")}
              >
                Back
              </button>
              <button className="pt_btn pt_btn_orange" onClick={handleSubmit}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </div>
  );
};
export default AddLobby;

import React, { useEffect } from 'react'
import { MainService } from '../../shared/services'

const ViewLobbyList = ({ state, dispatch }) => {
  const { isViewPopup, isDeActive, lobbyIdData, lobbyId } = state
  useEffect(() => {
    const getLobbyData = () => {
      MainService.getLobbyDetailsById({ id: lobbyId }).then(res => {
        if (res.success === true) {
          dispatch({ lobbyIdData: res.data })
        }
      })
    };
    getLobbyData();
    // eslint-disable-next-line
  }, [])


  return (
    <div
      className={`modal ${isViewPopup ? "admin-pop-up" : ""}`}
      style={{ paddingTop: "5%" }}
    >
      <div
        className={`modal-content ${isDeActive ? "inner-div" : ""}`}
        style={{ position: "relative" }}
      >
        <img
          className="close-img"
          src="/images/close.png"
          onClick={() => dispatch({ isViewPopup: false })}
          alt="close-img"
        />
        {/* <i className="fa fa-times" aria-hidden="true"></i> */}
        <h1 style={{ textAlign: "center" }}>View Lobby</h1>
        <div className="center-container">
          <div className="user-detail">
            <form>
              <div className="user-profile">
                <div className="user_form_wpr forgot_form_wpr">
                  <div className="form_group">
                    <label>Lobby ID</label>
                    <input
                      className="input"
                      type="text"
                      name="lobbyid"
                      value={lobbyIdData._id || ""}
                      placeholder="Enter lobby ID"
                      disabled
                    />
                  </div>
                  <div className="form_group">
                    <label>Lobby name</label>
                    <input
                      className="input"
                      type="text"
                      name="lobbyName"
                      value={lobbyIdData.lobby_name || ""}
                      placeholder="Enter Lobby name"
                      disabled
                    />
                  </div>
                  <div className="form_group">
                    <label>Type of Game</label>
                    <div className="label_section">
                      <label className="container">
                        Cash
                        <input
                          type="radio"
                          name="gameType"
                          checked={lobbyIdData.types_of_game === "cash"}
                          disabled
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="container">
                        Play with friends
                        <input
                          type="radio"
                          name="gameType"
                          checked={lobbyIdData.types_of_game === "PWF"}
                          disabled
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="form_group">
                    <label>No of Players </label>
                    <input
                      className="input"
                      name="player"
                      value={lobbyIdData.num_of_players || ""}
                      disabled
                    />
                  </div>
                  <div className="form_group">
                    <label>No of Players title and icon </label>
                    <div className="flex" style={{ display: "flex" }}>
                      <img
                        src={lobbyIdData.league_icon}
                        alt="setted icons"
                        className="selected-icon"
                      />
                      <input
                        className="input"
                        name="player"
                        value={lobbyIdData.league_name || ""}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form_group">
                    <label>subtitle</label>
                    <input
                      className="input"
                      type="text"
                      name="subtitle"
                      placeholder="Enter entry fee"
                      value={lobbyIdData.league_sub_text || "not added"}
                      disabled
                    />
                  </div>
                  <div className="form_group">
                    <label>Rake</label>
                    <input
                      className="input"
                      type="text"
                      name="rake"
                      placeholder="Enter entry fee"
                      value={lobbyIdData.rake || "0"}
                      disabled
                    />
                  </div>
                  <div className="form_group">
                    <label>winning amount</label>
                    <input
                      className="input"
                      type="text"
                      name="winning_amount"
                      placeholder="Enter entry fee"
                      value={lobbyIdData.winning_amount || "0"}
                      disabled
                    />
                  </div>
                  <div className="form_group">
                    <label>Entry fee</label>
                    <input
                      className="input"
                      type="text"
                      name="entryFee"
                      placeholder="Enter entry fee"
                      value={lobbyIdData.entery_fees || ""}
                      disabled
                    />
                  </div>
                  <div className="status">
                    <label>Enabled/Disabled</label>
                  </div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={
                        lobbyIdData.enable === true ? true : false || ""
                      }
                      disabled
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </form>
          </div>
          <div className="btn_group">
            <div>
              <div className="btn">
                <button
                  className="pt_btn pt_btn_orange"
                  onClick={() => dispatch({ isViewPopup: false })}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewLobbyList
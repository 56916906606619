import React from 'react'
import { useNavigate } from 'react-router-dom';

const AdminHeader = () => {
    const navigate = useNavigate()
    const handleLogout = () => {
        sessionStorage.clear();
        navigate("/")
    }
    return (
        <div className="topnav">
            <h4>Dashboard</h4>
            <div className="login-container">
                <button type="submit" onClick={handleLogout}>Logout</button>
            </div>
        </div >
    )
}
export default AdminHeader
import axios from "axios";
import { onError, onSuccess } from "../component/repetitive/RepetitiveFunction";
import constant from "./constant";

const api = axios.create();
api.defaults.baseURL = constant.API_URL;
export const setToken = token => {
  if (token) api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};
export const MainService = {
  login: (data) => api.post(`/login`, data).then(onSuccess, onError),

  lobbylist: (data) =>
    api
      .get(
        `/lobby/get?p=${data.p}&n=${data.n}&ord=${data.ord}&lobby_name=${data.name}`
      )
      .then(onSuccess, onError),
  updateLobby: (id, data) =>
    api
      .put(`/lobby/update/${id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(onSuccess, onError),

  deleteLobby: (id) =>
    api.delete(`/lobby/delete/${id}`).then(onSuccess, onError),

  addLobby: (data) =>
    api
      .post(`/lobby/add`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(onSuccess, onError),
  getLobbyDetailsById: (data) =>
    api.get(`/lobby/get/${data.id}`).then(onSuccess, onError),
  updateLobbyData: (id, data) =>
    api.put(`/lobby/update/${id}`, data).then(onSuccess, onError),
  addBot: (data) => api.post(`/createBot`, data).then(onSuccess, onError),
};  
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../shared/notification";
import { MainService, setToken } from "../../shared/services";
import AdminHeader from "../adminHeader/AdminHeader";
import { hookStateReducer } from "../repetitive/RepetitiveFunction";
import ViewLobbyList from "./ViewLobbyList";
import ReactPaginate from "react-paginate";
const is = {
  lobbyData: [],
  lobbyIdData: "",
  lobbyId: "",
  total: 0,
  modelist: [],
  gametypelist: [],
  no_of_deal: [],
  no_of_player: [],
  gameType: "all",
  selectedOrder: "asc",
  name: "",
  page: 1,
  pageSize: 10,
  isActive: false,
  isDeActive: false,
  isOpen: false,
  islodar: false,
  isViewPopup: false,
  botPopup: false,
  botname: "",
  botimg: "",
  checkdata: {}
};
const LobbyList = () => {
  const [state, dispatch] = useReducer(hookStateReducer, is);
  const token = sessionStorage.getItem("Token");
  const {
    gameType,
    selectedOrder,
    name,
    total,
    page,
    pageSize,
    lobbyData,
    isActive,
    isDeActive,
    isOpen,
    lobbyId,
    islodar,
    botPopup,
    isViewPopup,
    checkdata
  } = state;
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    fetchLobbyList();
    // eslint-disable-next-line
  }, [selectedOrder, gameType, page]);

  useEffect(() => {
    setPageCount(Math.ceil(total / pageSize));
    // eslint-disable-next-line
  }, [total, lobbyData]);

  const fetchLobbyList = () => {
    const data = {
      mode: gameType,
      n: pageSize,
      ord: selectedOrder,
      p: page,
      name: name,
    };
    setToken(token);
    MainService.lobbylist(data).then((res) => {
      if (res.success === true) {
        dispatch({
          lobbyData: res.data.results,
          total: res.data.totalResults,
        });
      } else {
        // console.log(res.response.data.message);
        Toast.fire({
          icon: "error",
          title: res.message,
        });
        if (res.response.data.message === "JsonWebTokenError: invalid token") {
          sessionStorage.clear();
          navigate("/");
        }
      }
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({ [name]: value });
  };
  const handleCheck = (item) => {
    const { _id, enable } = item;
    if (!enable) dispatch({ isActive: true, lobbyId: _id, checkdata: item });
    else dispatch({ isDeActive: true, lobbyId: _id, checkdata: item });
  };
  const closePopup = () => {
    dispatch({ isOpen: false, isActive: false, isDeActive: false });
    fetchLobbyList();
  };
  const deleteRow = (item) => {
    dispatch({ isOpen: true, lobbyId: item._id });
  };
  const activeUser = () => {
    const data = {
      ...checkdata,
      enable: isActive ? true : false,
    };
    delete data['_id']
    MainService.updateLobby(lobbyId, data).then((res) => {
      if (res.success) {
        closePopup();
        if (isActive) {
          Toast.fire({
            icon: "success",
            title: "Lobby Enabled!!",
          });
          dispatch({ isActive: false })
        } else if (isDeActive) {
          Toast.fire({
            icon: "success",
            title: "Lobby Disabled!!",
          });
          dispatch({ isDeActive: false })
        }
      } else {
        Toast.fire({
          icon: "error",
          title: res.message,
        });
      }
    });
  };
  const deleteLobby = () => {
    dispatch({ islodar: true });
    MainService.deleteLobby(lobbyId).then((res) => {
      // console.log(res);
      if (res.success === true) {
        dispatch({ islodar: false });
        closePopup();
        Toast.fire({
          icon: "success",
          title: res.message,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: res.message
            ? res.message
            : res.error.message
              ? res.error.message
              : res.error.errors.message,
        });
      }
    });
  };
  const handlePageClick = (event) => {
    const selectedPage = event.selected + 1;
    dispatch({ page: Math.ceil(selectedPage) });
  };

  const TABLE_HEADING = [
    "Lobby Id",
    "Lobby Name",
    "Types of games",
    "Player",
    "sub title",
    "icon",
    "Rake",
    "Total Time",
    "Remaining Time",
    "Entry fees",
    "Winning Amount",
    "bonus",
    "Enabled / Disabled",
    "Action",
  ];

  return (
    <>
      <AdminHeader />
      {islodar && <div id="cover-spin"></div>}
      <div className="dashboard-wrapper">
        <div className="inner-div">
          <div className="heading">
            <h3>Lobby List</h3>
          </div>
          <div className="center-container lobbylist">
            <div className="user-management">
              <div className="add-tab">
                <div className="add-new-btn ">
                  <span
                    className="add-btn"
                    onClick={() => navigate("/admin/add-lobby")}
                  >
                    <img src="/images/addplus-icon.svg" alt="plus-sign" />
                    Create Lobby
                  </span>
                  <span
                    className="add-btn"
                    onClick={() => dispatch({ botPopup: true })}
                  >
                    <img src="/images/addplus-icon.svg" alt="plus-sign" />
                    Create Bot
                  </span>
                  <select
                    className="sort-dd"
                    name="selectedOrder"
                    value={selectedOrder}
                    onChange={handleChange}
                  >
                    <option value="asc"> Asc</option>
                    <option value="desc">Desc</option>
                  </select>
                  <label className="filter_text">Types of Game : </label>
                  <select
                    className="sort-dd"
                    name="gameType"
                    value={gameType}
                    onChange={handleChange}
                  >
                    <option value="all">All</option>
                    <option value="cash">Cash</option>
                    <option value="PWF">PWF</option>
                  </select>
                  <div className="searchbar">
                    <input
                      className="sort-dd"
                      type="text"
                      placeholder="Search Lobby Name.."
                      name="name"
                      value={name}
                      onChange={handleChange}
                    />
                    <button>
                      <img
                        src="/images/search.png"
                        alt="search-img"
                        onClick={() => fetchLobbyList()}
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-listing">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        {TABLE_HEADING.map((name, index) => {
                          return <th key={index}>{name}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {lobbyData.length ? (
                        lobbyData.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item._id}</td>
                              <td>{item.lobby_name}</td>
                              <td>{item.types_of_game}</td>
                              <td>
                                {item.num_of_players}&nbsp;&nbsp;
                                {item.league_name}
                              </td>
                              <td>{item.league_sub_text}</td>
                              <td>
                                <img
                                  src={item.league_icon}
                                  alt="icon for event"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </td>
                              <td>{item.rake}</td>
                              <td>{item.timer}</td>
                              <td>{item.remainingTime}</td>
                              <td>{item.entery_fees}</td>
                              <td>{item.winning_amount || 0}</td>
                              <td>{item.bonus || 0}</td>
                              <td>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={item.enable}
                                    onChange={() => handleCheck(item)}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                              <td className="action_icon">
                                <span>
                                  <span className="submit_check tooltip">
                                    <img
                                      src="/images/view-icon.svg"
                                      alt="add-img"
                                      onClick={() =>
                                        dispatch({
                                          isViewPopup: true,
                                          lobbyId: item._id,
                                        })
                                      }
                                    />
                                    <span className="tooltiptext">View</span>
                                  </span>
                                </span>
                                <span>
                                  <span className="submit_check tooltip">
                                    <img
                                      src="/images/ad-edit-icon.svg"
                                      alt="edit-img"
                                      onClick={() => {
                                        navigate(
                                          `/admin/edit-lobby/${item._id}`
                                        );
                                      }}
                                    />
                                    <span className="tooltiptext">Edit</span>
                                  </span>
                                </span>
                                <span
                                  className="submit_check tooltip"
                                  onClick={() => deleteRow(item)}
                                >
                                  <img
                                    src="/images/ad-delete-icon.svg"
                                    alt="delete-img"
                                  />
                                  <span className="tooltiptext del-rat-tooltip">
                                    Delete
                                  </span>
                                </span>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="9" className="no-data">
                            <p>No data found</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {total > 10 && (
                  <div className="pagination-wrapper">
                    <div>
                      <ReactPaginate
                        breakLabel="..."
                        previousLabel="<<"
                        nextLabel=" >>"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal ${isOpen ? "admin-pop-up" : ""}`}>
        <div className={`modal-content ${isOpen ? "inner-div" : ""}`}>
          <div>
            <img src="/images/ad-popup-delete.svg" alt="delete-img" />
          </div>
          <h1>Delete Lobby</h1>
          <p>Are you sure! Do you want to Delete this lobby?</p>

          <div className="btn_group">
            <button className="pt_btn1 pt_btn_orange" onClick={closePopup}>
              Cancel
            </button>
            <button className="pt_btn pt_btn_orange" onClick={deleteLobby}>
              {" "}
              Delete
            </button>
          </div>
        </div>
      </div>
      <div className={`modal ${isActive ? "admin-pop-up" : ""}`}>
        <div className={`modal-content ${isActive ? "inner-div" : ""}`}>
          <div>
            <img src="/images/Que.svg" alt="que-img" />
          </div>
          <h1>Enable Lobby</h1>
          <p>Do you want to enable this lobby?</p>
          <div className="btn_group">
            <button className="pt_btn1 pt_btn_orange" onClick={closePopup}>
              Cancel
            </button>
            <button className="pt_btn pt_btn_orange" onClick={activeUser}>
              {" "}
              Yes
            </button>
          </div>
        </div>
      </div>
      <div className={`modal ${isDeActive ? "admin-pop-up" : ""}`}>
        <div className={`modal-content ${isDeActive ? "inner-div" : ""}`}>
          <div>
            <img src="/images/Que.svg" alt="que-img" />
          </div>
          <h1>Disable Lobby</h1>
          <p>Do you want to disable this lobby?</p>
          <div className="btn_group">
            <button className="pt_btn1 pt_btn_orange" onClick={closePopup}>
              Cancel
            </button>
            <button className="pt_btn pt_btn_orange" onClick={activeUser}>
              {" "}
              Yes
            </button>
          </div>
        </div>
      </div>
      {isViewPopup && <ViewLobbyList state={state} dispatch={dispatch} />}
      {botPopup && <BotPopup state={state} dispatch={dispatch} />}
    </>
  );
};

export default LobbyList;

const BotPopup = ({ state, dispatch }) => {
  const { isDeActive, botPopup, botname, botimg } = state;
  const [error, setError] = useState({ botname: false, botimg: false });
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({ [name]: value });
    if (name === "botname" && value !== "")
      setError({ ...error, botname: false });
    else if (name === "botimg" && value !== "")
      setError({ ...error, botimg: false });
  };
  const handleSubmit = () => {
    if (botname === "" && botimg === "")
      return setError({ botname: true, botimg: true });
    const data = {
      profilePic: botimg,
      userName: botname,
    };
    MainService.addBot(data).then((res) => {
      if (res.status === 200) {
        Toast.fire({
          icon: "success",
          title: res.message,
        });
        dispatch({ botPopup: false });
      } else {
        Toast.fire({
          icon: "error",
          title: res.message,
        });
      }
    });
  };
  return (
    <div
      className={`modal ${botPopup ? "admin-pop-up" : ""}`}
      style={{ paddingTop: "5%" }}
    >
      <div
        className={`modal-content ${isDeActive ? "inner-div" : ""}`}
        style={{ position: "relative" }}
      >
        <img
          className="close-img"
          src="/images/close.png"
          onClick={() => dispatch({ botPopup: false })}
          alt="close-img"
        />
        <h1 style={{ textAlign: "center" }}>Create Bot</h1>
        <div className="center-container">
          <div className="user-detail">
            <form>
              <div className="user-profile">
                <div className="user_form_wpr forgot_form_wpr">
                  <div className="form_group">
                    <label>Bot name</label>
                    <input
                      className="input"
                      type="text"
                      name="botname"
                      placeholder="Enter Bot name"
                      onChange={handleChange}
                    />
                    <div className="invalid-feedback">
                      {error.botname && (
                        <span style={{ color: "red" }}>
                          Bot name is required.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form_group">
                    <label>Bot image</label>
                    <input
                      className="input"
                      type="text"
                      name="botimg"
                      placeholder="Enter Bot image URL"
                      onChange={handleChange}
                    />
                    <div className="invalid-feedback">
                      {error.botimg && (
                        <span style={{ color: "red" }}>
                          Bot img is required.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="btn_group">
            <div>
              <div className="btn">
                <button className="pt_btn pt_btn_orange" onClick={handleSubmit}>
                  Save
                </button>
                <button
                  className="pt_btn pt_btn_orange"
                  onClick={() => dispatch({ botPopup: false })}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from 'react'

const Loader = () => {
  return (
    <div id='cover-spin'>
        {/* <img
            style={{ width: "50px", height: "50px", margin: "auto" }}
            src="/images/small_loader.gif"
            alt="loder"
        /> */}
</div>
  )
}

export default Loader
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const PrivateRoute = (props) => {
    const navigate = useNavigate();
    let Cmp = props.cmp;
    useEffect(() => {
        if (!sessionStorage.getItem("Token")) {
          navigate("/");
        }
    }, []);
    return (
            <div>
                <Cmp />
            </div>
    );
};


export default PrivateRoute